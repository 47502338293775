import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleRollingToBalance = () => {
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>콤프</h5>
                      <span>COMP</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button
                        className="mm-btn"
                        onClick={() => {
                          this.props.handleChange(popupView.point); //금고
                        }}
                      >
                        금고
                      </button>
                      <button className="mm-btn active">콤프</button>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유콤프</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.rolling)}
                          />
                        </div>
                      </div>
                 
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>신청금액</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 10000
                                }`,
                              });
                            }}
                          >
                            1만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 50000
                                }`,
                              });
                            }}
                          >
                            5만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 100000
                                }`,
                              });
                            }}
                          >
                            10만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 500000
                                }`,
                              });
                            }}
                          >
                            50만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 1000000
                                }`,
                              });
                            }}
                          >
                            100만원
                          </button>
                          <button
                            className="danger-web-btn mt-2"
                            onClick={() => {
                              this.setState({ balance: "0" });
                            }}
                          >
                            정정
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button onClick={this.handleRollingToBalance}>
                          <i className="fas fa-check-square"></i> 콤프전환
                        </button>
                        <button
                          className="gray"
                          data-dismiss="modal"
                          onClick={() => {
                            this.props.handleClose();
                          }}
                        >
                          <i className="fa fa-window-close"></i> 취소하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="Modal_modal_wrapper__bmYcZ">
              <div className="Modal_modal_overlay__Ag4FI animate__animated animate__fadeIn"></div>
              <div className="Modal_modal_inner_wrapper__2J_Td">
                <div className="Modal_app_modal__WUCt9 animate__animated animate__bounceIn">
                  <div className="Modal_modal_header__3CHHh">
                    <img src="/images/logo.png" />
                    <button
                      className="Modal_close_modal__3PQ7Q"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="Modal_modal_options__1UDxt">
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit); //입금신청
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw); //출금신청
                      }}
                    >
                      출금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.point); //금고
                      }}
                    >
                      금고
                    </button>
                    <button className="Modal_active__2zgwL">콤프</button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.user); //마이페이지
                      }}
                    >
                      마이페이지
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.help); //고객센터
                      }}
                    >
                      고객센터
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.notice); //공지사항
                      }}
                    >
                      공지사항
                    </button>
                  </div>
                  <div className="Mobile_modal_options__2LBvR"></div>
                  <div className="web-alert">
                    보이스피싱 및 3자사기로 협박하더라도 협상 및 타협 절대없음 *
                  </div>
                  <table className="Modal_modal_table__2W6GA">
                    <tbody>
                      <tr>
                        <th style={{ color: "#fff" }}>보유머니</th>
                        <td style={{ whiteSpace: "nowrap", color: "#fff" }}>
                          {ConverMoeny(this.props.user.balance)}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>금고머니</th>
                        <td style={{ whiteSpace: "nowrap", color: "#fff" }}>
                          {ConverMoeny(this.props.user.point)}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>아이디</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="아이디"
                            readOnly
                            value={this.props.user.id}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>예금주명</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="예금주명"
                            readOnly
                            value={this.props.user.bankowner}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>회원은행</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="회원은행"
                            readOnly
                            value={this.props.user.bankname}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>신청금액</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 10000
                                }`,
                              });
                            }}
                          >
                            1만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 50000
                                }`,
                              });
                            }}
                          >
                            5만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 100000
                                }`,
                              });
                            }}
                          >
                            10만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 500000
                                }`,
                              });
                            }}
                          >
                            50만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 1000000
                                }`,
                              });
                            }}
                          >
                            100만원
                          </button>
                          <button
                            className="danger-web-btn mt-2"
                            onClick={() => {
                              this.setState({ balance: "0" });
                            }}
                          >
                            정정
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-center w-100 py-3">
                    <button
                      className="dark-web-btn"
                      onClick={this.handleRollingToBalance}
                    >
                      콤프전환
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
